import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

let queueProcessorTimer = null

export const state = {
  ...BaseState,
  eventQueue: [],
  isProcessing: false,
}

export const mutations = {
  ...BaseMutations,
  QUEUE_EVENT(state, event) {
    state.eventQueue.push(event)
  },
  CLEAR_QUEUE(state) {
    state.eventQueue = []
  },
  SET_PROCESSING(state, isProcessing) {
    state.isProcessing = isProcessing
  },
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  logEvent({ commit, dispatch }, event) {
    commit("QUEUE_EVENT", event)
    dispatch("startQueueProcessor")
  },
  startQueueProcessor({ dispatch }) {
    if (queueProcessorTimer) {
      clearTimeout(queueProcessorTimer)
    }
    queueProcessorTimer = setTimeout(() => {
      dispatch("processQueue")
    }, 10000)
  },
  async processQueue({ state, commit }) {
    if (state.isProcessing || state.eventQueue.length === 0) {
      return
    }

    commit("SET_PROCESSING", true)

    try {
      await axios.post("/stats", { stats: state.eventQueue })
      commit("CLEAR_QUEUE")
    } catch (error) {
      console.error("Failed to process analytics queue", error)
    } finally {
      commit("SET_PROCESSING", false)
    }
  },

  // ===
  // Compilers for viewing analytics
  // - getReportViews
  // - getReportCompanyViews
  // ===

  async getViews({}, payload) {
    try {
      const response = await axios.get("/stats", payload)
      return response.data
    } catch (error) {
      console.error("Failed to fetch views for", payload.compiler, error)
      throw error
    }
  },
}
